import { DropdownItem } from "../components/forms/DropdownField";

export const conditionTypes : DropdownItem[] = [
    {
        key: "NA",
        value: "Default"
    },
    {
        key: "single",
        value: "Direct"
    },
    {
        key: "and",
        value: "All Conditions Match"
    },
    {
        key: "or",
        value: "Any Conditions Match"
    },
    {
        key: "nested",
        value: "Nested"
    },
    {
        key: "value",
        value: "Value"
    }
];

export function MapToConditionType(key : string) : string {
    let type = "Default";
    
    conditionTypes.forEach((item, index) => {
        if(item.key === key){
            type = item.value;
        }
    });

    return type;
}